import React from "react"
import { Container, Row } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container className="page-frame">
        <Row className="page-heading">
          not found
        </Row>
        <Row className="page-body">
          Lost in the heat of it all
          <br /><br />
          Girl you know you're lost
          <br /><br />
          Lost in the thrill of it all
        </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
